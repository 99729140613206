<template>
    <h1>
        <span v-if="isEdit == 'true'">Edit Blog</span>
        <span v-else>Add Blog</span>
    </h1>
    <br><hr><br>
    <div style="display: flex; gap: 10px; flex-direction: column; ">
        <v-row>
            <v-col>
                <label>Banner</label> <span v-if="errors.banner" style="color: #B91E3A;">{{ errors.banner[0] }}</span>
                <label for="bannerFile">
                    <div style="border: 2px dotted; border-radius: 10px; height: 300px; display: flex; justify-content: center; align-items: center;">
                        <input id="bannerFile" type="file" hidden @change="handleBannerFile">
                        <img v-if="bannerPreview || bannerEdit == true" :src="bannerEdit == true ? data.banner : bannerPreview" alt="Banner Preview" style="max-height: 200px; object-fit: cover;">
                        <span v-else>Choose file (1366 x 768)</span>
                    </div>
                </label>
            </v-col>
            <v-col>
                <label>Thumbnail</label> <span v-if="errors.thumbnail" style="color: #B91E3A;">{{ errors.thumbnail[0] }}</span>
                <label for="thumbnailFile">
                    <div style="border: 2px dotted; border-radius: 10px; height: 300px; display: flex; justify-content: center; align-items: center;">
                        <input id="thumbnailFile" type="file" hidden @change="handleThumbnailFile">
                        <img v-if="thumbnailPreview || thumbnailEdit == true" :src="thumbnailEdit == true ? data.thumbnail : thumbnailPreview" alt="Thumbnail Preview" style="max-height: 200px; object-fit: cover;">
                        <span v-else>Choose file (847 x 574)</span>
                    </div>
                </label>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <v-text-field
                    v-model="data.title"
                    label="Title"
                    :maxlength="60"
                    :error-messages="errors.title"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-select
                    v-model="data.category_id"
                    :items="blogsCategories"
                    label="Categories"
                    required
                    :error-messages="errors.category_id"
                ></v-select>
            </v-col>
            <v-col cols="12" md="6" style="display: flex; flex-direction: column;">
                <label style="font-size: 12px;">Date Publish</label>
                <input style="border: 2px solid #F6F6F6; border-bottom: 1px solid gray; border-radius: 5px; padding: 5px 10px;" type="datetime-local" v-model="data.published_date" @input="updatePublishedDate">
                <span style="color: #B91E3A;" v-if="errors.content">{{ errors.published_date[0] }}</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <editor
                    api-key="ik4d2k4k5qq0gf1mrqp8z02rhm04nhoxeea793tu6xnihfdi"
                    :init="{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist autolink anchor code codesample',
                            'lists link searchreplace fullscreen',
                            'insertdatetime image media table paste help wordcount',
                            'hr visualchars nonbreaking textpattern',
                            'imagetools noneditable'
                        ],
                        toolbar: 'bold italic underline strikethrough | fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist table | insertfile image media link instagram | forecolor backcolor removeformat | code fullscreen',
                        images_upload_url: '/blogs/upload',
                        file_picker_types: 'image',
                        convert_urls: false,
                        image_title: true,
                        automatic_uploads: true,
                    }"
                    v-model="data.content"
                    @input="contentData"
                />
                <span style="color: #B91E3A;" v-if="errors.content">{{ errors.content[0] }}</span>
            </v-col>
        </v-row>
    </div>
    <br>
    <h1>SEO</h1>
    <br><hr><br>
    <div style="display: flex; flex-direction: column;">
        <v-row>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="data.meta_title"
                    label="Meta Title"
                    :maxlength="60"
                    :error-messages="errors.meta_title"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="data.meta_description"
                    label="Meta Description"
                    :maxlength="160"
                    :error-messages="errors.meta_description"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    v-model="data.meta_keywords"
                    label="Meta Keywords"
                    :rules="[textAndNumbersRule]"
                    :error-messages="errors.meta_keywords"
                ></v-text-field>
            </v-col>
        </v-row>
    </div>
    <div style="display: flex; justify-content: flex-end; gap: 20px;">
        <button style="background-color: #00AEEE; color: white; padding: 10px 20px; border-radius: 10px;" @click.prevent="updateBlog()" v-if="isEdit == 'true'">Update</button>
        <button style="background-color: #00AEEE; color: white; padding: 10px 20px; border-radius: 10px;" @click.prevent="saveBlog()" v-else>Save</button>

        <button style="background-color: gray; color: white; padding: 10px 20px; border-radius: 10px;" @click="routeTo('/cms/blogs')">Cancel</button>
    </div>
</template>

<script setup>

import { onMounted, ref } from 'vue';
import Editor from '@tinymce/tinymce-vue'
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

const routeTo = (url) => {
   router.push(url);
};
const blogSlug = ref(route.query.blogSlug);
const isEdit = ref(route.query.isEdit);

const bannerFile = ref();
const bannerEdit = ref();
const thumbnailFile = ref();
const thumbnailEdit = ref();

const bannerPreview = ref();
const thumbnailPreview = ref();
const blogsCategories = ref();

// utils
const textAndNumbersRule = (value) => {
    const pattern = /^[a-zA-Z0-9.,!?:;\-()—'`₱$%" ]*$/;
    return pattern.test(value) || "Only letters and numbers are allowed"
}

const data = ref({
    title: '',
    category_id: '',
    published_date: '',
    content: '',
    thumbnail: thumbnailFile,
    banner: bannerFile,
    meta_title: '',
    meta_description: '',
    meta_keywords: '',
});

const errors = ref([]);

const fetchData = () => {

    axios.get('/blogs/api/categories')
    .then(response => {
         blogsCategories.value = response.data.map(item => ({ value: item.id, title: item.category_name }));
    })
    .catch(error => {

    });
}

const fetchEditData = () => {
    axios.get('/cms/blogs/edit/'+blogSlug.value)
    .then(response => {
        data.value.title = response.data.title;
        data.value.category_id = response.data.category_id;
        data.value.published_date = response.data.published_date;
        data.value.content = response.data.content;
        data.value.meta_title = response.data.meta_title;
        data.value.meta_description = response.data.meta_description;
        data.value.meta_keywords = response.data.meta_keywords;

        data.value.banner = response.data.banner;
        data.value.thumbnail = response.data.thumbnail;

        bannerEdit.value = true;
        thumbnailEdit.value = true;
    })
    .catch(error => {

    });
}

const saveBlog = () => {

    axios.post('/cms/blogs/save', data.value,{
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    .then(response => {
        alert(response.data);
        data.value = {};
        router.push('/cms/blogs/');
    })
    .catch(error => {
        errors.value = error.response.data.errors;

        setTimeout(() => {
            errors.value = [];
        }, 5000);
    });
}

const updateBlog = () => {

axios.post('/cms/blogs/update/'+blogSlug.value, data.value,{
    headers: {
        'Content-Type': 'multipart/form-data',
    },
})
.then(response => {
    alert(response.data);
    data.value = {};
    router.push('/cms/blogs/');
})
.catch(error => {
    errors.value = error.response.data.errors;

    setTimeout(() => {
        errors.value = [];
    }, 5000);
});
}

const handleBannerFile = (event) => {
  const file = event.target.files[0];

  if (file) {
    bannerFile.value = new File([file], file.name, { type: file.type });

    // Use FileReader to display image preview
    const reader = new FileReader();
    reader.onload = (e) => {
        bannerPreview.value = e.target.result; // Set the preview source
    };
    reader.readAsDataURL(file);

    bannerEdit.value = false;
  } else {

  }
};

const handleThumbnailFile = (event) => {
  const file = event.target.files[0];

  if (file) {
    thumbnailFile.value = new File([file], file.name, { type: file.type });

    // Use FileReader to display image preview
    const reader = new FileReader();
    reader.onload = (e) => {
        thumbnailPreview.value = e.target.result; // Set the preview source
    };
    reader.readAsDataURL(file);
    thumbnailEdit.value = false;
  } else {

  }
};


const contentData = (content) => {
    data.content = content;
}

const updatePublishedDate = (event) => {
  data.published_date = event.target.value;
};

onMounted(()=>{
    fetchData();
    if(isEdit.value == 'true') {
        fetchEditData();
    }
});

</script>

import { createWebHistory, createRouter } from "vue-router";

//MAIN NAVIGATION
import Dashboard from './components/modules/cms/modules/dashboard.vue';

//BLOGS
import Blogs from '@components/modules/cms/modules/blogs.vue';
import FormBlog from '@components/modules/cms/modules/blogs/form-blog.vue';

import Events from '@components/modules/cms/modules/events/events.vue';
import FormEvents from '@components/modules/cms/modules/events/form-events.vue';

const routes = [
    {
        path: '/cms',
        children: [
            {
                path: '',
                component: Dashboard,
            },
            {
                path: 'blogs',
                children: [
                    {
                        path: '',
                        component: Blogs,
                    },
                    {
                        path: 'form-blog',
                        component: FormBlog,
                    }
                ],
            },
            {
                path: 'events',
                children: [
                    {
                        path: '',
                        component: Events,
                    },
                    {
                        path: 'form-events',
                        component: FormEvents,
                    }
                ],
            },
        ]
    },

]
const router = createRouter({
    history:createWebHistory(),
    routes
});

export default router;

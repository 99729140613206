<template>
    <h1>
        <span v-if="isEdit == 'true'">Edit Event</span>
        <span v-else>Add Event</span>
    </h1>
    <br><hr><br>
    <div class="flex flex-col gap-6">
        <v-row>
            <v-col>
                <label class="font-semibold">Banner</label> <span v-if="errors.banner" style="color: #B91E3A;">{{ errors.banner[0] }}</span>
                <label for="bannerFile">
                    <div style="border: 2px dotted; border-radius: 10px; height: 300px; display: flex; justify-content: center; align-items: center;">
                        <input id="bannerFile" type="file" hidden @change="handleBannerFile">
                        <img v-if="bannerPreview || bannerEdit == true" :src="bannerEdit == true ? data.banner : bannerPreview" alt="Banner Preview" style="max-height: 200px; object-fit: cover;">
                        <span v-else>Choose file (1366 x 768)</span>
                    </div>
                </label>
            </v-col>
            <v-col>
                <label class="font-semibold">Thumbnail</label> <span v-if="errors.thumbnail" style="color: #B91E3A;">{{ errors.thumbnail[0] }}</span>
                <label for="thumbnailFile">
                    <div style="border: 2px dotted; border-radius: 10px; height: 300px; display: flex; justify-content: center; align-items: center;">
                        <input id="thumbnailFile" type="file" hidden @change="handleThumbnailFile">
                        <img v-if="thumbnailPreview || thumbnailEdit == true" :src="thumbnailEdit == true ? data.thumbnail : thumbnailPreview" alt="Thumbnail Preview" style="max-height: 200px; object-fit: cover;">
                        <span v-else>Choose file (847 x 574)</span>
                    </div>
                </label>
            </v-col>
        </v-row>
        <div class="flex items-center gap-6 w-full">
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Title
                </span>
                <input
                    v-model="data.title"
                    @input="validateInput"
                    type="text"
                    name="title"
                    maxlength="100"
                    class="rounded-md border p-2"
                    placeholder="Event title"
                >
            </div>
            <div class="relative flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Registration Link
                </span>
                <input
                    v-model="data.link"
                    type="text"
                    name="link"
                    class="rounded-md border p-2"
                    placeholder="Registration Link"
                >
                <span
                    v-if="errors['link']"
                    class="absolute -bottom-4 text-smicos-secondary text-xs font-semibold"
                >
                    {{ errors['link'] }}
                </span>
            </div>
        </div>
        <div class="flex items-center gap-6 w-full">
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Event Start
                </span>
                <input
                    v-model="data.event_start"
                    type="datetime-local"
                    name="event_start"
                    class="rounded-md border p-2"
                >
            </div>
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Event End
                </span>
                <input
                    v-model="data.event_end"
                    type="datetime-local"
                    name="event_event"
                    class="rounded-md border p-2"
                >
            </div>
        </div>
        <div class="flex items-center gap-6 w-full">
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Description
                </span>
                <editor
                    api-key="ik4d2k4k5qq0gf1mrqp8z02rhm04nhoxeea793tu6xnihfdi"
                    :init="{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist autolink anchor code codesample',
                            'lists link searchreplace fullscreen',
                            'insertdatetime image media table paste help wordcount',
                            'hr visualchars nonbreaking textpattern',
                        ],
                        toolbar: 'bold italic underline strikethrough | fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist table | insertfile image media link instagram | forecolor backcolor removeformat | code fullscreen',
                        convert_urls: false,
                    }"
                    v-model="data.description"
                />
            </div>
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Key Topics
                </span>
                <editor
                    api-key="ik4d2k4k5qq0gf1mrqp8z02rhm04nhoxeea793tu6xnihfdi"
                    :init="{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist autolink anchor code codesample',
                            'lists link searchreplace fullscreen',
                            'insertdatetime image media table paste help wordcount',
                            'hr visualchars nonbreaking textpattern',
                        ],
                        toolbar: 'bold italic underline strikethrough | fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist table | insertfile image media link instagram | forecolor backcolor removeformat | code fullscreen',
                        convert_urls: false,
                    }"
                    v-model="data.key_topics"
                />
            </div>
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Event Summary
                </span>
                <editor
                    api-key="ik4d2k4k5qq0gf1mrqp8z02rhm04nhoxeea793tu6xnihfdi"
                    :init="{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist autolink anchor code codesample',
                            'lists link searchreplace fullscreen',
                            'insertdatetime image media table paste help wordcount',
                            'hr visualchars nonbreaking textpattern',
                        ],
                        toolbar: 'bold italic underline strikethrough | fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist table | insertfile image media link instagram | forecolor backcolor removeformat | code fullscreen',
                        convert_urls: false,
                    }"
                    v-model="data.event_summary"
                />
            </div>
        </div>
        <div class="flex flex-col">
            <span class="font-semibold">
                Other Contents (Link)
            </span>
            <input
                v-model="data.other_contents[0].links"
                class="resize-none border rounded-lg p-2"
            />
        </div>
        <div class="flex flex-col">
            <span class="font-semibold">
                Other Contents (Speakers Description)
            </span>
            <editor
                api-key="ik4d2k4k5qq0gf1mrqp8z02rhm04nhoxeea793tu6xnihfdi"
                :init="{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink anchor code codesample',
                        'lists link searchreplace fullscreen',
                        'insertdatetime image media table paste help wordcount',
                        'hr visualchars nonbreaking textpattern',
                    ],
                    toolbar: 'bold italic underline strikethrough | fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist table | insertfile image media link instagram | forecolor backcolor removeformat | code fullscreen',
                    convert_urls: false,
                }"
                v-model="data.other_contents[0].speaker_description"
            />
        </div>
        <div class="flex flex-col">
            <span class="font-semibold">
                Other Contents (Description)
            </span>
            <editor
                api-key="ik4d2k4k5qq0gf1mrqp8z02rhm04nhoxeea793tu6xnihfdi"
                :init="{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink anchor code codesample',
                        'lists link searchreplace fullscreen',
                        'insertdatetime image media table paste help wordcount',
                        'hr visualchars nonbreaking textpattern',
                    ],
                    toolbar: 'bold italic underline strikethrough | fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist table | insertfile image media link instagram | forecolor backcolor removeformat | code fullscreen',
                    convert_urls: false,
                }"
                v-model="data.other_contents[0].description"
            />
        </div>
        <div class="flex flex-wrap gap-5 flex-col">
            <span class="font-semibold">
                Event Speaker/s
            </span>
            <div class="flex flex-wrap gap-5">
                <div @click="addSpeaker()" class="border rounded-md w-[400px] h-[600px] flex flex-col gap-2 justify-center items-center cursor-pointer">
                    <span class="text-[24px] text-gray-500 font-bold border-4 border-gray-500 px-5 py-2 rounded-full">+</span>
                    <span class="font-semibold text-gray-500">Add Speaker</span>
                </div>
                <div v-for="(row, index) in data.speakers" :key="index" class="border rounded-md w-[400px] h-[600px] flex flex-col gap-5 p-5">
                    <div class="w-full flex justify-end">
                        <span @click="removeSpeaker(index)" class="font-bold bg-red-500 py-1 px-2 rounded-full w-8 text-center text-white cursor-pointer">x</span>
                    </div>
                    <label>
                        <div style="border: 2px dotted; border-radius: 10px; height: 300px; display: flex; justify-content: center; align-items: center;">
                            <input type="file" hidden @change="event => handleSpeakerFile(event, index)">
                            <img v-if="data.speakers[index].image_url || data.speakers[index].image" class="w-[100px]" :src="data.speakers[index].image_url ? data.speakers[index].image_url : data.speakers[index].image" alt="">
                            <span v-else>Choose file (356 x 314)</span>
                        </div>
                    </label>
                    <input class="px-2 py-1 border rounded-md" type="text" v-model="data.speakers[index].name" placeholder="Name">
                    <input class="px-2 py-1 border rounded-md" type="text" v-model="data.speakers[index].position" placeholder="Position">
                    <input class="px-2 py-1 border rounded-md" type="text" v-model="data.speakers[index].organization" placeholder="Organization">
                </div>
            </div>
        </div>

        <!-- SEO -->
        <h1 class="font-semibold border-b-[2px] border-black pb-4">
            SEO
        </h1>
        <div class="flex items-center gap-4">
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Meta Title
                </span>
                <input
                    v-model="data.meta_title"
                    type="text"
                    name="event_start"
                    class="rounded-md border p-2"
                    placeholder="Meta Title"
                >
            </div>
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Meta Description
                </span>
                <input
                    v-model="data.meta_description"
                    type="text"
                    name="event_event"
                    class="rounded-md border p-2"
                    placeholder="Meta Description"
                >
            </div>
            <div class="flex flex-col gap-2 flex-1">
                <span class="font-semibold">
                    Meta Keywords
                </span>
                <input
                    v-model="data.meta_keywords"
                    type="text"
                    name="event_start"
                    class="rounded-md border p-2"
                    placeholder="Meta Keywords"
                >
            </div>
        </div>
        <div class="flex items-center justify-end gap-6">
            <button
                v-if="isEdit == 'true'"
                @click.prevent="updateEvent()"
                class="bg-[#00AEEE] text-white rounded-md px-6 py-2"
            >
                Update
            </button>
            <button
                v-else
                @click.prevent="saveEvent()"
                class="bg-[#00AEEE] text-white rounded-md px-6 py-2"
            >
                Save
            </button>
            <button
                @click="routeTo('/cms/events')"
                class="bg-gray-400 text-white rounded-md px-6 py-2"
            >
                Cancel
            </button>
        </div>
    </div>
</template>

<script setup>

import { onMounted, ref } from 'vue';
import Editor from '@tinymce/tinymce-vue'
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

const routeTo = (url) => {
   router.push(url);
};
const eventSlug = ref(route.query.eventSlug);
const isEdit = ref(route.query.isEdit);

const bannerFile = ref();
const bannerEdit = ref();
const thumbnailFile = ref();
const thumbnailEdit = ref();

const speakerFile = ref();
const speakerEdit = ref();
const speakerPreview = ref();

const bannerPreview = ref();
const thumbnailPreview = ref();

const data = ref({
    title: "",
    link: "",
    description: "",
    key_topics: "",
    event_summary: "",
    event_start: "",
    event_end: "",
    speakers: [{
        name: "",
        position: "",
        organization: "",
        image: "",
    }],
    other_contents: [{
        links: "",
        description: "",
        speaker_description: "",
    }],
    thumbnail: thumbnailFile,
    banner: bannerFile,
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
});

// utils
const validateInput = (event) => {
    const pattern = /^[a-zA-Z0-9.,!?:;\-()`" ]*$/;
    if (!pattern.test(event.target.value)) {
        event.target.value = event.target.value
            .replace(/[^a-zA-Z0-9.,!?:;\-()`" ]/g, "")
            .replace(/[\p{Emoji_Presentation}\p{Emoji}\u200d\uFE0F]/gu, "");
        data.value.title = event.target.value;
    }
}

const addSpeaker = () => {
    data.value.speakers.push({
        name: "",
        position: "",
        organization: "",
        image: "",
    });
}

const removeSpeaker = (index) => {
    data.value.speakers.splice(index, 1);
}

const errors = ref([]);

const fetchEditData = () => {
    window.axios.post(`/cms/events/${eventSlug.value}/edit`)
    .then(response => {
        data.value.title = response.data.title;
        data.value.link = response.data.registration_link;
        data.value.description = response.data.description;
        data.value.key_topics = response.data.key_topics;
        data.value.event_summary = response.data.event_summary;
        data.value.event_start = response.data.start_at;
        data.value.event_end = response.data.end_at;
        data.value.meta_title = response.data.meta_title;
        data.value.meta_description = response.data.meta_description;
        data.value.meta_keywords = response.data.meta_keywords;
        data.value.speakers = JSON.parse(response.data.speakers);

        data.value.other_contents[0].links = JSON.parse(response.data.contents).links;
        data.value.other_contents[0].speaker_description = JSON.parse(response.data.contents).speaker_description;
        data.value.other_contents[0].description = JSON.parse(response.data.contents).description;

        data.value.banner = response.data.banner;
        data.value.thumbnail = response.data.thumbnail;

        bannerEdit.value = true;
        thumbnailEdit.value = true;
    })
    .catch(error => {
        console.error(error);
    });
}

const saveEvent = () => {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    if (!data.value.link.match(urlPattern)) {
        errors.value['link'] = "Invalid URL format";
    }

    axios.post('/cms/events/store', data.value,{
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    .then(response => {
        alert(response.data);
        data.value = {};
        router.push('/cms/events');
    })
    .catch(error => {
        console.error(error);
    });
}

const updateEvent = () => {
    axios.post(`/cms/events/${eventSlug.value}/update`, data.value,{
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    .then(response => {
        alert(response.data);
        data.value = {};
        router.push('/cms/events');
    })
    .catch(error => {
        errors.value = error.response.data.errors;

        setTimeout(() => {
            errors.value = [];
        }, 5000);
    });
}

const handleBannerFile = (index) => {
  const file = event.target.files[0];

  if (file) {
    bannerFile.value = new File([file], file.name, { type: file.type });

    // Use FileReader to display image preview
    const reader = new FileReader();
    reader.onload = (e) => {
        bannerPreview.value = e.target.result; // Set the preview source
    };
    reader.readAsDataURL(file);


    bannerEdit.value = false;
  } else {

  }
};

const handleSpeakerFile = (event, index) => {
  const file = event.target.files[0];

  if (file) {
        data.value.speakers[index].image = new File([file], file.name, { type: file.type });
        data.value.speakers[index].image_url = URL.createObjectURL(file);
    } else {
        data.value.speakers[index].image = null;
        data.value.speakers[index].image_url = null;
    }
};

const handleThumbnailFile = (event) => {
  const file = event.target.files[0];

  if (file) {
    thumbnailFile.value = new File([file], file.name, { type: file.type });

    // Use FileReader to display image preview
    const reader = new FileReader();
    reader.onload = (e) => {
        thumbnailPreview.value = e.target.result; // Set the preview source
    };
    reader.readAsDataURL(file);

    thumbnailEdit.value = false;
  } else {

  }
};

onMounted(()=>{
    if(isEdit.value == 'true') {
        fetchEditData();
    }
});

</script>

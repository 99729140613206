<template>
    <div style="display: flex; justify-content: space-between; align-items: center;">
        <h1>Events</h1>
        <div style="display: flex; gap: 20px;">
        <input 
            @change="fetchData()" 
            v-model="search" 
            type="text" 
            placeholder="Search" 
            style="padding: 10px 20px; border: 1px solid black; border-radius: 10px;" 
            :append-inner-icon="icons.search"
        >
        <button 
            @click="routeTo('/cms/events/form-events')"
            style="background-color: #00AEEE; padding: 10px 20px; border-radius: 10px; color: white; font-weight: 500;"
        >
                <v-icon color="white" :icon="icons.add" size="large" ></v-icon>
                <span>Add Event</span>
        </button>
        </div>
    </div>
    <br>
    <v-table fixed-header height="500px" density="compact" style="border-radius: 10px !important;">
        <thead style="background-color: #00AEEE;">
            <tr>
                <th class="text-left">
                Title
                </th>
                <th class="text-left" width="30%">
                Start at
                </th>
                <th class="text-left" width="30%">
                End at
                </th>
                <th class="text-left" width="10%">
                Actions
                </th>
            </tr>
        </thead>
        <tbody v-if="events.length != 0">
            <tr
                v-for="event in events"
                :key="event.id"
                style="height: 50px;"
            >
                <td>{{ event.title }}</td>
                <td>{{ formattedDate(event.start_at) }}</td>
                <td>{{ formattedDate(event.end_at) }}</td>
                <td>
                    <div style="display: flex; gap: 15px;">
                        <button 
                            @click="routeTo('/cms/events/form-events',{ eventSlug: event.id, isEdit: true})"
                            style="background-color: yellow; padding: 5px 15px; border-radius: 10px; color: white; font-size: 15px;" 
                        >
                            <v-icon color="black" :icon="icons.edit" size="large" ></v-icon>
                        </button>
                        <button 
                            @click.prevent="deleteEvent(event.id)"  
                            style="background-color: red; padding: 5px; border-radius: 10px; color: white; font-size: 15px;" 
                        >
                            <v-icon color="black" :icon="icons.delete" size="large" ></v-icon>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td colspan="6" class="text-center" style="height: 450px !important;">
                    <v-chip
                    class="ma-2"
                    color="red"
                    text-color="white"
                    style="font-size: 18px; font-weight: bold;"
                    >
                    No Results
                    </v-chip>
                </td>
            </tr>
        </tbody>
    </v-table>
</template>

<script setup>
import { ref, onMounted } from 'vue';

import {
    mdiTextBoxEditOutline,
    mdiTrashCanOutline,
    mdiNotePlusOutline,
} from '@mdi/js';

const icons = ref({
    edit: mdiTextBoxEditOutline,
    delete: mdiTrashCanOutline,
    add:mdiNotePlusOutline,
});

const events = ref([]);
const search = ref(null);

import { useRouter } from 'vue-router';

const router = useRouter();

const routeTo = (route, data) => {
   router.push({
       path: route,
       query: data
   });
};

const fetchData = () => {
    axios.post('/cms/events', {
        params: {
            search: search.value
        }
    })
    .then(response => {
        events.value = response.data;
    })
    .catch(error => {
        console.error(error);
    });
}

const deleteEvent = (id) => {
    window.axios.post(`/cms/events/${id}/delete`)
    .then(response => {
        alert(response.data);
        fetchData();
    })
    .catch(error => {
        console.error(error);
    });
}

const formattedDate = (value) => {
    // Assuming blog.value.published_date is a valid date string
    const date = new Date(value);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

onMounted(() => {
    fetchData();
});

</script>

<style scoped>
    th{
        background-color: #00AEEE !important;
        font-family: 'Gotham', sans-serif !important;
        color:white !important;
        font-weight: 700 !important;
    }

    td{
        text-transform: capitalize !important;
    }
</style>

import './bootstrap';
import { createApp } from 'vue';
import App from './components/modules/cms/App.vue';
import Routes from './routes';

//VUETIFY
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi,
        },
    },
    theme: {
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: "#8a3d92",
                },
            }
        },
    },
})

createApp(
{
    components:{
        App,
    }

}).use(Routes).use(vuetify).mount('#app2');

<template>
<v-card>
    <v-layout>
      <v-navigation-drawer
        expand-on-hover
        rail
      >
        <v-list>
          <v-list-item
            prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg"
            :title="user.name"
            :subtitle="user.email"
          ></v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list density="compact" nav>
            <v-list-item @click="routeTo('/cms')" title="Dashboard" :prepend-icon="icons.dashboard"></v-list-item>
            <v-list-item @click="routeTo('/cms/blogs')" title="Blogs" :prepend-icon="icons.blogs"></v-list-item>
            <v-list-item @click="routeTo('/cms/events')" title="Events" :prepend-icon="icons.events"></v-list-item>
            <v-list-item @click="logout()" title="Logout" value="starred" :prepend-icon="icons.logout"></v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main style="height: 100%; margin:50px;">
        <router-view></router-view>
      </v-main>

    </v-layout>
  </v-card>
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router';
import {
    mdiViewDashboard,
    mdiNewspaperVariantMultipleOutline,
    mdiLogout,
    mdiCalendarMultiple 
} from '@mdi/js';

const icons = ref({
    dashboard: mdiViewDashboard,
    blogs: mdiNewspaperVariantMultipleOutline,
    logout: mdiLogout,
    events: mdiCalendarMultiple 
});

const props = defineProps(['user']);

const router = useRouter();

const routeTo = (route) => {
   router.push(route);
};

const logout = () => {
    axios.post('/logout')
        .then(response => {
            window.location.href = '/login';
        })
        .catch(error => {
            console.log(error);
        });
    }
</script>
<style scoped>
.v-list-item--nav .v-list-item-title {
    color: black;
}
a{
    color: black;
    text-decoration: none;
}
</style>
